import React from 'react'
import Layout from "../components/layout"
import Seo from "../components/seo"
import TaxSlabs20232024 from "../components/tax-slabs-2023-2024"

function TaxSlabs2023() {
    return (
        <Layout>
            <TaxSlabs20232024></TaxSlabs20232024>
        </Layout>
    );
}
export const Head = () => <Seo title="Tax Slabs FY 2023-2024 | Tax Calculator Pakistan" description="The following slabs and income tax rates will be applicable for salaried persons for the years 2023-2024 according to the new budget." />

export default TaxSlabs2023;